import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useRef, useContext } from "react"
import { TableContext } from "..";
import { get } from "lodash"
import TableIconButton from "../../TableIconButton";
import * as XLSX from 'xlsx';

export default function ExportDataButton(props) {

    const { columns, rows, label } = useContext(TableContext);
    const downloadElem = useRef(null);

    const handleClick = () => {
        const report = rows.map(r => {
            let mappedRow = {}
            columns.filter(c => !c.hide).forEach(c => {
                if (c.customValue) mappedRow[c.label] = c.customValue(r)
                else if (typeof r[c.id] === "string") mappedRow[c.label] = get(r, c.id, "")
                else mappedRow[c.label] = get(r, c.id, "")
            })
            return mappedRow
        })

        const worksheet = XLSX.utils.json_to_sheet(report);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        downloadElem.current.href = URL.createObjectURL(blob);
        downloadElem.current.download = label ? `${label}.xlsx` : "Reporte.xlsx";
        downloadElem.current.click();
    }

    return <>
        <TableIconButton onClick={handleClick} icon={<Download />} tooltip="Exportar" />
        <a style={{ display: "none" }} ref={downloadElem} />
    </>
}