import { SendToMobile } from "@mui/icons-material";
import ModalFrame from "../../../components/form/ModalFrame";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Alert, Stack } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useDisclosure } from "react-use-disclosure";
import FormAddress from "../../../components/form/FormAddress";
import FormDateInput from "../../../components/form/FormDateInput";
import FormInput from "../../../components/form/FormInput";
import FormTimeInput from "../../../components/form/FormTimeInput";
import TableIconButton from "../../../components/TableIconButton";
import { setSnackBar } from "../../../redux/snackbar";

export default function CreateTaskAction({ selectedRows = [] }) {
	
	const { isOpen, open, close } = useDisclosure(false);
	const dispatch = useDispatch();

	const GQLCreatePackage = gql`
		mutation createPackage(
			$ownerEmail: String
			$address: JSONObject!
			$title: String!
			$description: String!
			$categoryId: Int!
			$subcategoryId: Int
			$price: Float!
			$userTerms: String
			$providerTerms: String
			$workDate: DateTime
			$media: [JSONObject]
			$acquisitionMethodId: Int
			$externalId: String
			$totalPrice: Float!
			$adminNotes: String
			$revenueRate: Float
			$ownerFirstName: String
			$ownerLastName: String
			$branch: String
			$seller: String
			$dni: String
		) {
			createdPackage: createPackage(
				ownerEmail: $ownerEmail
				address: $address
				title: $title
				description: $description
				categoryId: $categoryId
				subcategoryId: $subcategoryId
				price: $price
				userTerms: $userTerms
				providerTerms: $providerTerms
				workDate: $workDate
				media: $media
				acquisitionMethodId: $acquisitionMethodId
				externalId: $externalId
				totalPrice: $totalPrice
				adminNotes: $adminNotes
				revenueRate: $revenueRate
				ownerFirstName: $ownerFirstName
				ownerLastName: $ownerLastName
				branch: $branch
				seller: $seller
				dni: $dni
			) {
				id
			}
		}
	`;
	const GQLValidateDniAndExternalId = gql`
		query validateDniAndExternalId(
			$acquisitionMethodId: Int
			$externalId: String
			$dni: String
		) {
			validateDniAndExternalId: validateDniAndExternalId(
				dni: $dni
				externalId: $externalId
				acquisitionMethodId: $acquisitionMethodId
			) {
				dni
				externalId
			}
		}
	`;

	const [CreatePackage] = useMutation(GQLCreatePackage, { fetchPolicy: "network-only" });
	const [ValidateDniAndExternalId] = useLazyQuery(GQLValidateDniAndExternalId, { fetchPolicy: "network-only" });

	const createTaskInApp = async (pkg) => {
		const {
			acquisitionMethodId,
			title,
			description,
			email,
			address,
			price,
			workDate,
			workTime,
			userTerms,
			providerTerms,
			externalId,
			totalPrice,
			adminNotes,
			revenueRate,
			firstName,
			lastName,
			branch,
			seller,
			dni,
		} = pkg;
		const selectedBundleTask = selectedRows[0];
		if (!messageWarning) {
			const { data: { validateDniAndExternalId } = {} } = await ValidateDniAndExternalId({
				variables: {
					acquisitionMethodId,
					externalId,
					dni,
				},
			});
			let messageWarning = null;
			if (validateDniAndExternalId?.dni && validateDniAndExternalId?.externalId) messageWarning = "El dni y el id externo ya existen en la base de datos, verifique los datos antes de continuar";
			else if (validateDniAndExternalId?.dni) messageWarning = "El dni ya existe en la base de datos, verifique el dato antes de continuar";
			else if (validateDniAndExternalId?.externalId) messageWarning = "El id externo ya existe en la base de datos, verifique el dato antes de continuar";
			setMessageWarning(messageWarning);
			if (messageWarning) {
				dispatch(setSnackBar({ text: messageWarning, severity: "warning" }));
				return;
			}
		}
		workDate.setHours(workTime.getHours());
		workDate.setMinutes(workTime.getMinutes());
		
		const { data: { createdPackage } = {}, errors } = await CreatePackage({
			variables: {
				price: parseFloat(price),
				ownerEmail: email,
				address,
				workDate,
				ownerFirstName: firstName,
				ownerLastName: lastName,
				branch,
				seller,
				dni,
				title: title ?? selectedBundleTask?.taskData?.title,
				description: description ?? selectedBundleTask?.taskData?.description,
				categoryId: selectedBundleTask?.taskData?.categoryId,
				subcategoryId: selectedBundleTask?.taskData?.subcategoryId ?? null,
				userTerms: userTerms ?? selectedBundleTask?.taskData?.userTerms,
				providerTerms: providerTerms ?? selectedBundleTask?.taskData?.providerTerms,
				media: selectedBundleTask?.taskData?.media,
				acquisitionMethodId,
				externalId,
				adminNotes,
				totalPrice: parseFloat(totalPrice),
				revenueRate: parseFloat(revenueRate),
			},
		});
		if (errors || !createdPackage) dispatch(setSnackBar({ text: "Ocurrio un error al crear el paquete.", severity: "error" }));
		else if (createdPackage) {
			dispatch(setSnackBar({ text: "Tarea creada correctamente." }));
			close();
		}
	};

	const validate = (values) => {
		const errors = {};
		// fillable fields
		if (!values.workDate) errors.workDate = "Required";
		if (!values.workTime) errors.workTime = "Required";
		if (!values.email) errors.email = "Required";
		if (!values?.address?.lat) errors.address = "Required";

		// auto filled fields
		if (!values.title) errors.title = "Required";
		if (!values.price) errors.price = "Required";
		if (!values.description) errors.description = "Required";
		if (!values.userTerms) errors.userTerms = "Required";
		if (!values.providerTerms) errors.providerTerms = "Required";

		if (!values.firstName) errors.firstName = "Required";
		if (!values.lastName) errors.lastName = "Required";

		return errors;
	};

	const [messageWarning, setMessageWarning] = useState("");
	
	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<SendToMobile />} tooltip="Crear tarea empaquetada" />

			<Form
				
				onSubmit={createTaskInApp}
				initialValues={{ ...selectedRows[0]?.taskData }}
				validate={validate}
				render={({ handleSubmit, values, valid }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={
							<Stack direction={"column"} gap={2}>
								<span>Generar paquete en Manno</span>
								{messageWarning && <Alert severity="warning">{messageWarning}</Alert>}
							</Stack>
						}
						submitDisabled={!valid}
						buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							<Stack direction={"row"} gap={2}>
								<FormInput label="Email" name="email" autoFocus required />
								<FormDateInput name="workDate" label="Fecha del trabajo" disablePast required />
								<FormTimeInput name="workTime" label="Hora del trabajo" required />
							</Stack>
							<Stack direction={"row"} gap={2}>
								<FormInput label="Nombre del Cliente" name="firstName" required />
								<FormInput label="Apellido del Cliente" name="lastName" required />
							</Stack>
							<Stack direction={"row"} gap={2}>
								<FormInput label="Sucursal" name="branch" />
								<FormInput label="Vendedor" name="seller" />
							</Stack>
							<Stack direction={"row"} gap={2}>
								<FormInput label="dni / factura" name="dni" onChange={() => setMessageWarning(null)} />
								<FormInput label="Id Externo" name="externalId" required onChange={() => setMessageWarning(null)}/>
							</Stack>

							<FormAddress label="Direccion" name="address"  required/>
							<FormInput label="Notas" name="adminNotes" type="textarea" />

							{/* datos precargados */}
							<Stack direction="row" gap={2}>
								<FormInput label="Título" name="title" required />
								<FormInput label="Importe a abonar al experto" name="price" type="number" required fullWidth={false} />
								<FormInput label="% de Ganancia" name="revenueRate" type="number" required fullWidth={false} />
							</Stack>
							<Stack direction="row" gap={2}>
								<FormInput label="Descripcion" name="description" required />
								<FormInput label="Importe cobrado al usuario" name="totalPrice" type="number" required fullWidth={false} />
							</Stack>
							<Stack direction="row" spacing={2}>
								<FormInput label="TyC Usuario" name="userTerms" multiline rows={8} />
								<FormInput label="TyC Expertos" name="providerTerms" multiline rows={8} />
							</Stack>
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
