import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import SideTabs from "../../components/SideTabs";
import PkgInformation from "./components/PkgInformation";
import ProviderApplications from "./components/ProviderApplications";

export default function TaskDetail(params, props) {
	const routeParams = useParams();

	const Query = gql`
		query getCompletePackage($packageId: Int!) {
			pkg: getCompletePackage(packageId: $packageId) {
				id
				title
				description
				status
				owner {
					id
					firstName
					lastName
					email
				}
				media {
					id
					uri
				}
				address {
					lat
					lng
					inline
					street
					number
					state
					locality
				}
				applications {
					id
					# comment
					asignedApplicationsCount
					createdAt
					owner {
						id
						adminNotes
						firstName
						lastName
						profilePicture
					}
				}
			}
		}
	`;

	const { data: { pkg = {} } = {}, error, refetch } = useQuery(Query, { fetchPolicy: "network-only", variables: { packageId: parseInt(routeParams.id) } });

	return (
		<SideTabs
			tabs={[
				{ title: "Informacion del paquete", component: <PkgInformation pkg={pkg ?? {}} /> },
				{ title: "Aplicantes", component: <ProviderApplications pkg={pkg} refresh={refetch} /> },
			]}
		/>
	);
}

const styles = {
	container: {
		// backgroundColor: "green",
	},
	avatar: {
		width: 80,
		height: 80,
		borderRadius: "10%",
		shadow: 2,
	},
};
