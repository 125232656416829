import React from "react";

export default function Attribute({ label, value, outlined = false }) {
	return (
		<div
			style={{
				border: outlined ? "1px solid black" : "none",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				// backgroundColor: "red",
				width: "100%",
				alignItems: "top",
				gap: 10,
				marginBottom: "5px 0 3px 0",
			}}>
			<label style={{ fontWeight: "bold", width: "30%" }}>{label}: </label>
			<p style={{ width: "70%", textAlign: "left" }}>{value}</p>
		</div>
	);
}
