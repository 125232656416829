import { Clear } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";

export default function FormInput({ name, fullWidth = true, onChange, ...props }) {
	const { input, meta } = useField(name);
	if (props.type === "textarea") {
		props.multiline = true;
		props.minRows = 5;
		props.maxRows = Infinity;
	}
	if (props.cleareable) {
		props.InputProps = {
			endAdornment: (
				<IconButton
					sx={{ visibility: input.value ? "visible" : "hidden" }}
					onClick={() => {
						input.onChange(null);
					}}>
					<Clear />
				</IconButton>
			),
		};
	}
	return (
		<TextField
			style={{ width: fullWidth ? "100%" : undefined }}
			{...input}
			{...props}
			id={name}
			error={meta.error && meta.touched}
			helperText={meta?.error}
			onChange={(event) => {
				input.onChange(event); // Llama al onChange original
				if (onChange) {
					onChange(event); // Llama al onChange personalizado si existe
				}
			}}
		/>
	);
}
